<template>
  <div id="PurchaseBillScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9">
          <purchase-bill></purchase-bill>
        </v-col>
        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="white">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  dense
                  outlined
                  label="Nomor Faktur"
                  v-model="noInvoice"
                  clearable
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Nomor PO"
                  v-model="noPo"
                  clearable
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="getPurchaseBillBySearch"
                :loading="loading"
                :disabled="loadingStatus"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PurchaseBill from "@/components/Widget/mitra/PurchaseBillList";

export default {
  components: {
    PurchaseBill
  },

  data() {
    return {
      loading: false,
      valid: true,
      fromDate: "",
      toDate: "",
      noInvoice: "",
      noPo: "",
      menuFromDate: false,
      menuToDate: false
    };
  },

  mounted() {
    this.$store.dispatch("purchaseBill/getAll");
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    }
  },

  methods: {
    getPurchaseBillBySearch() {
      this.loading = true;

      let params = {
        start_date: this.fromDate,
        end_date: this.toDate,
        invoice_number: this.noInvoice,
        po_number: this.noPo
      };

      this.$store
        .dispatch("purchaseBill/getBySearch", params)
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
