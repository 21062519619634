<template>
  <v-card class="my-10">
    <v-app-bar dense color="transparent">
      <v-toolbar-title>
        <h5>Daftar Produk</h5>
      </v-toolbar-title>
    </v-app-bar>

    <v-data-table
      v-if="products[0].price"
      :headers="headers"
      :items="products"
      mobile-breakpoint="0"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ props.item.product_name }}</td>
          <td>{{ props.item.product_code }}</td>
          <td>
            {{ formatAmount(props.item.quantity) }}
          </td>
          <td>{{ props.item.unit }}</td>
          <td>
            {{ formatRupiah(props.item.price) }}
          </td>
          <td>{{ formatRupiah(props.item.subtotal) }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-data-table
      v-else
      :headers="headers"
      :items="products"
      mobile-breakpoint="0"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ props.item.product_name }}</td>
          <td>{{ props.item.product_code }}</td>
          <td>
            {{ formatAmount(props.item.quantity_order) }}
          </td>
          <td>{{ props.item.unit }}</td>
          <td>
            {{ formatRupiah(props.item.price_order) }}
          </td>
          <td>{{ formatRupiah(props.item.subtotal) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  props: ["products"],
  mixins: [globalMixin],

  data() {
    return {
      headers: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah Produk", sortable: false },
        { text: "Satuan", sortable: false },
        { text: "Harga Satuan", sortable: false },
        { text: "Sub Total", sortable: false }
      ]
    };
  }
};
</script>
