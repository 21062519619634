<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Tagihan Pembelian</h5>
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="refreshData">mdi-refresh</v-icon>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="purchaseBillsData"
            :loading="loadingStatus"
            loading-text="Sedang Menyiapkan Data..."
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td
                  class="detailLink"
                  @click="getPurchaseBillById(props.item.id)"
                >
                  {{ props.item.po_number }}
                </td>
                <td>{{ props.item.invoice_number }}</td>
                <td>{{ getDate(props.item.invoice_date) }}</td>
                <td>{{ props.item.payment_method }}</td>
                <td>{{ props.item.status }}</td>
                <td>{{ convertMinutes(props.item.due_date) }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor Faktur: {{ purchaseBillDetail.invoice_number }}</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-container>
          <v-row>
            <v-col class="col-3">
              <div class="text-body-1 text--primary">
                Nomor PO
              </div>
            </v-col>
            <v-col class="col-9">
              <div class="text-body-1 text--primary">
                :&ensp; {{ purchaseBillDetail.po_number }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-3">
              <div class="text-body-1 text--primary">
                Metode Pembayaran
              </div>
            </v-col>
            <v-col class="col-9">
              <div class="text-body-1 text--primary">
                :&ensp; {{ purchaseBillDetail.payment_method }}
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="col-3">
              <div class="text-body-1 text--primary">
                Tanggal Pembayaran
              </div>
            </v-col>
            <v-col class="col-9">
              <div class="text-body-1 text--primary">
                :&ensp; {{ purchaseBillDetail.payment_date }}
              </div>
            </v-col>
          </v-row>

          <product-table :products="purchaseBillDetail.details"></product-table>

          <v-row>
            <v-col class="col-6 col-md-9">
              <div class="text-h6 text--primary text-end">
                Jumlah Barang:
              </div>
            </v-col>
            <v-col class="col-6 col-md-3">
              <div class="text-h6 text--primary text-end">
                {{ formatAmount(purchaseBillDetail.total_item) }}
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="col-6 col-md-9">
              <div class="text-h6 text--primary text-end">
                Total:
              </div>
            </v-col>
            <v-col class="col-6 col-md-3">
              <div class="text-h6 text--primary text-end">
                {{ formatRupiah(purchaseBillDetail.total_price) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import ProductTable from "@/components/Table/ProductTable";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

export default {
  mixins: [globalMixin],

  components: {
    ProductTable
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: "Nomor PO", sortable: false },
        { text: "Nomor Faktur", sortable: false },
        { text: "Tanggal Faktur", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Status", sortable: false },
        { text: "Jatuh Tempo Dalam", sortable: false }
      ]
    };
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    purchaseBillsData() {
      return this.$store.getters["purchaseBill/getPurchaseBills"];
    },
    purchaseBillDetail() {
      return this.$store.getters["purchaseBill/getPurchaseBillDetail"];
    }
  },

  methods: {
    getPurchaseBillById(id) {
      this.$root.$loaderModal.start("Loading...");

      let params = {
        id: id
      };

      this.$store
        .dispatch("purchaseBill/getById", params)
        .then(() => {
          this.dialog = true;
        })
        .catch(() => {
          this.$tostini({
            message: ERROR_SOMETHING_WRONG,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    refreshData() {
      this.$store.dispatch("purchaseBill/getAll");
    }
  }
};
</script>
